import React from 'react';
import { ThemeProvider } from '@emotion/react'
import styled from "@emotion/styled"
import 'normalize.css';
import 'fontsource-roboto/latin-400.css';
import 'fontsource-roboto/latin-700.css';
import 'fontsource-roboto-slab/latin-700-normal.css';
import logoIcon from './logo-icon.png';
import logoText from './logo-text.png';
import { Nav } from './Nav';
import { Global, css } from '@emotion/react'
import { css as cssClassName } from '@emotion/css'
import defaultBannerImageSrc from '../images/banner/tents-under-stars.jpg';
import { Footer } from './Footer';
import { CenteredContainer } from './styles';
import { urls } from '../urls';
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const WanderlostHead = () => (
  <Helmet>
    <meta name="format-detection" content="telephone=no" />
    <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta charSet="utf-8" />
  </Helmet>
);

const AppBarContainer = styled.div(({ showBanner }) => `
    height: 90px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    ${!showBanner ? `
        background-color: black;
        background-image: url(${defaultBannerImageSrc});
        background-size: cover;
        background-position: 50% 60%;
    ` : ''}
`);

const AppBarContent = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
`;

const Banner = styled.div(() => `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: center;

    .inner {
        position: relative;
        z-index: 1000;
        width: 100%;
        width: 100%;
        max-width: 960px;
        margin: 0 auto;
        padding-left: 16px;
        padding-right: 16px;
    }

    .title {
        color: #fff;
        font-family: 'Roboto Slab', serif;
        font-size: 24px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 0.15em;
        text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    }

    p {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        font-size: 16px;
        line-height: 1.5;
        list-style: none;
        margin: 0;
    }

    .cta-container {
        display: flex;
        justify-content: center;
    }

    .cta {
        font-size: 16px;
        font-weight: bold;
        padding: 1rem;
        background-color: #F8AB00;
        color: #fff;
        border-radius: 16px;
        transition: background-color 250ms;
        text-transform: uppercase;
        text-shadow: 2px 2px 0 rgba(31, 84, 72, 0.2);
        margin-top: 1rem;
    }

    .cta:hover, .cta:active, .cta:focus {
        background-color: #E26429;
    }

    @media (min-width: 768px) {
        .title {
            font-size: 36px;
        }

        p {
            font-size: 20px;
        }

        .cta {
            font-size: 20px;
            padding: 1.25rem;
            margin-top: 2rem;
        }
    }
`);

const Content = styled.div(({ showBanner, noPadding, theme }) => ({
    ...(!noPadding && {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    }),
    ...(!showBanner && {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
    }),
}));

const LogoContainer = styled.div`
    a {
        display: inline-flex;
        align-items: center;
    }
`;

const LogoIconImg = styled.img`
    height: 60px;
    margin-right: 8px;
`;

const LogoTextImg = styled.img`
    height: 32px;
    position: relative;
    top: 2px;
    display: none;

    @media (min-width: 430px) {
        display: inline-block;
    }
`;

const Container = styled.div`

`;

const globalStyles = css`

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

a { color: #F36B22; }
a:hover, a:active { color: #D35C1C; }

body {
    background: #fff;
    font-family: Roboto, sans-serif;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    color: #1E2322;
}

h2 {
    margin: 1.5rem 0 0.5rem;
}

h3 {
    margin: 1.5rem 0 0.5rem;
}

p {
    margin: 0 0 1rem;
}

`;

const theme = {
  headingColor: 'blue',
  spacing: factor => `${factor * 8}px`,
  typography: {
      button: {
          small: {
              fontSize: 16,
          },
          normal: {
              fontSize: 20,
          },
          large: {
              fontSize: 22,
          }
      }
  },
  colors: {
    primary: {
      main: '#E26429',
      dark: '#B64F18',
    },
    secondary: {
      main: '#5BAC9A',
      dark: '#3D7266',
      light: '#F5FBF9',
    },
    text: {
      textPrimary: '#526D67',
      textSecondary: '#526D67',
      contrast: '#D7D7D7'
    }
  }
};

const sliderStyles = {
    'awssld__wrapper': cssClassName(`
        height: 400px;

        @media (min-width: 960px) {
            height: 600px;
        }
    `),
    'awssld__bar': cssClassName(`
        opacity: 0;
    `)
};

export const Layout = ({ children, bannerSlides = null, showBanner = true, showBannerContent = true, noPadding = false }) => {
    return (
        <ThemeProvider theme={theme}>
            <WanderlostHead />
            <Global styles={globalStyles}/>
            <Container>
                <AppBarContainer showBanner={showBanner}>
                    <CenteredContainer>
                        <AppBarContent>
                            <LogoContainer>
                                <a href="/">
                                    <LogoIconImg
                                        src={logoIcon}
                                        alt="Wanderlost Logo Icon: Illustration of a sunrise behind mountains"
                                    />
                                    <LogoTextImg
                                        src={logoText}
                                        alt="Wanderlost Project"
                                    />
                                </a>
                            </LogoContainer>
                            <Nav />
                        </AppBarContent>
                    </CenteredContainer>
                </AppBarContainer>

                {showBanner && Boolean(bannerSlides) &&
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <AutoplaySlider
                            play={true}
                            interval={8000}
                            cancelOnInteraction={false}
                            bullets={false}
                            organicArrows={bannerSlides.length > 1}
                            infinite={bannerSlides.length > 1}
                            cssModule={sliderStyles}
                        >
                            {bannerSlides.map((slide, index) => (
                                <div data-src={slide.imageSrc} key={index}>
                                    <Banner>
                                        <div className="inner">
                                            {Boolean(slide.title) &&
                                                <h2 className="title">
                                                    {slide.title}
                                                </h2>
                                            }
                                            {Boolean(slide.description) &&
                                                <p>{slide.description}</p>
                                            }
                                            {/* {Boolean(slide.description || slide.title) &&
                                                <div className="cta-container">
                                                    <a href={urls.signup} className="cta">
                                                        Try Now
                                                    </a>
                                                </div>
                                            } */}
                                        </div>
                                    </Banner>
                                </div>
                            ))}
                        </AutoplaySlider>
                    </div>
                }

                <Content noPadding={noPadding} showBanner={showBanner}>
                    {children}
                </Content>

                <Footer />
            </Container>
        </ThemeProvider>
    );
}

