import React from 'react';
import styled from "@emotion/styled"
import { urls } from '../urls';

const Container = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
`;

const NavItem = styled.div(({ theme, color = 'primary', variant = 'default' }) => `
    font-size: 14px;
    margin-right: 8px;

    a {
        color: #fff;
        transition: all 300ms ease-in-out;
        font-weight: bold;
        padding: ${theme.spacing(1.5)};
        display: block;
        line-height: 1;
        text-transform: uppercase;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
        border: 2px solid transparent;
        border-radius: 18px;
    }

    a:hover, a:focus {
        color: ${theme.colors[color].main};
    }

    ${variant === 'contained' ?
        `
        a {
            background-color: ${theme.colors[color].main};
            color: #fff;
            text-shadow: none;
        }

        a:hover, a:focus {
            background-color: ${theme.colors[color].dark};
            color: #fff;
        }
        `
    : ''}

    ${variant === 'outlined' ?
        `
        a {
            border-color: ${theme.colors[color].main};
            color: #fff;
        }
        `
    : ''}

    @media (min-width: 430px) {
        font-size: 16px;
        line-height: 1.75;
        margin-right: 16px;
    }
`);

export const Nav = () => (
    <Container>
        <NavItem>
            <a href="/ourstory">About</a>
        </NavItem>
        {/* <NavItem color="primary" variant="outlined">
            <a href={urls.login}>Login</a>
        </NavItem>
        <NavItem color="primary" variant="contained">
            <a href={urls.signup}>Join</a>
        </NavItem> */}
    </Container>
);
