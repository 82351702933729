import React from 'react';
import { P, CenteredContainer } from './styles';
import styled from "@emotion/styled"
import InstagramLogo from '../images/social/instagram.inline.svg';
import FacebookLogo from '../images/social/facebook.inline.svg';
import YouTubeLogo from '../images/social/youtube.inline.svg';
import { urls } from '../urls';
import logoSrc from './logo-icon.svg';

const Container = styled.div(({ theme }) => `
    background-color: #1E2322;
    color: #fff;
    padding: ${theme.spacing(4)} 0 ${theme.spacing(6)};
    text-align: center;

    a {
        color: ${theme.colors.text.contrast};
        transition: color 300ms ease-in-out;
    }

    a:hover {
        color: ${theme.colors.primary.main};
    }

    .logo {
        margin: ${theme.spacing(4)} 0;
    }

    .logo img {
        max-width: 125px;
    }
`);

const Content = styled.div(({ theme }) => `
    background-color: #1E2322;
    color: #fff;

    .links a {
        display: block;
    }

    @media (min-width: 768px) {

        .links {
            display: flex;
            justify-content: center;
        }

        .links a {
            padding: 0 ${theme.spacing(4)};
        }

    }
`);

const Social = styled.div(({ theme }) => `
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing(4)};

    a {
        margin-right: ${theme.spacing(2)};
    }

    svg {
        transition: fill 300ms ease-in-out;
        fill: ${theme.colors.text.contrast};
    }

    svg:hover {
        fill: ${theme.colors.primary.main};
    }
`);

export const Footer = () => {
    return (
        <Container>
            <CenteredContainer>
                <Content>
                    <P className="links">
                        <a href="/ourstory">About Us</a>
                        {/* <a href={urls.signup}>Try Now</a> */}
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/terms-of-service">Terms of Service</a>
                    </P>
                </Content>
                <P className="logo">
                    <img src={logoSrc} alt="Wanderlost Project Logo" />
                </P>
                <P color="contrast">
                    <strong>ALWAYS WANDERING, NEVER LOST</strong>
                </P>
                <P color="contrast">
                    ©2021, The Wanderlost Project, All Rights Reserved | <a href="mailto:info@thewanderlostproject.com">info@thewanderlostproject.com</a>
                </P>
                <Social>
                    <a href="https://www.instagram.com/the.wanderlost.project" target="_blank" rel="noreferrer">
                        <InstagramLogo />
                    </a>
                    <a href="https://www.facebook.com/thewanderlostproject/" target="_blank" rel="noreferrer">
                        <FacebookLogo />
                    </a>
                    <a href="https://www.youtube.com/channel/UCwMyYoHZtoTKwN1gyP91Wrg" target="_blank" rel="noreferrer">
                        <YouTubeLogo />
                    </a>
                </Social>
            </CenteredContainer>
        </Container>
    );
}
