import React from 'react';
import styled from "@emotion/styled"
import contoursSrc from '../images/contours.png';

export const CenteredContainer = styled.div(({ noPadding = false }) => `
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    ${!noPadding ? `
        padding-left: 16px;
        padding-right: 16px;
    ` : ''}
`);

export const P = styled.p(props => ({
    textAlign: props.align,
    color: props.color === 'inherit' ? 'inherit' : props.theme.colors.text[props.color || 'textPrimary'],
    lineHeight: 1.5,
}));

export const ButtonLink = styled.a(({ theme, variant, color, size = 'medium' }) => ({
    padding: theme.spacing(2),
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    ...theme.typography.button[size],
    lineHeight: 1,
    transition: 'all 400ms ease-in-out',
    borderRadius: 18,
    cursor: 'pointer',

    ...(variant === 'link' && {
        color: theme.colors[color].main,

        '&:hover': {
            color: theme.colors[color].dark,
        }
    }),

    ...(variant === 'contained' && {
        backgroundColor: theme.colors[color].main,
        color: '#fff',

        '&:hover': {
            color: '#fff',
            backgroundColor: theme.colors[color].dark,
        }
    }),

    ...(variant === 'outlined' && {
        color: theme.colors[color].main,
        border: `2px solid ${theme.colors[color].main}`,
        '&:hover': {
            color: theme.colors[color].dark,
            borderColor: theme.colors[color].dark,
        }
    }),
}));

export const Divider = styled.div(({ theme, align = 'left', color = 'primary' }) => ({
    borderTop: `7px solid ${theme.colors[color].main}`,
    width: 90,
    margin: `${theme.spacing(2)} ${align === 'center' ? 'auto' : '0'}`,
}));

const BackgroundSectionTitle = styled.h2`
    text-align: center;
    margin: 0 0 2rem;
    font-size: 28px;
`;

const BackgroundSectionContainer = styled.div(({ theme, pull = false  }) => `
    background-color #112E28;
    background-image: url(${contoursSrc});
    background-repeat: repeat;
    padding: ${theme.spacing(2)};

    .content {
        border-radius: 12px;
        background-color: #fff;
        padding: ${theme.spacing(4)};
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 100;
        overflow: hidden;
        max-width: 1600px;
        margin: 0 auto;
    }

    @media (min-width: 768px) {
        padding: 0 ${theme.spacing(10)};
        padding: ${theme.spacing(6)};

        ${pull && `
            .content {
                top: -${theme.spacing(10)};
                margin-bottom: -${theme.spacing(4)};
            }
            `
        }
    }
`);

export const BackgroundSection = ({ title, children, pull }) => (
    <BackgroundSectionContainer pull={pull}>
        <div className="content">
            <BackgroundSectionTitle>{title}</BackgroundSectionTitle>
            {children}
        </div>
    </BackgroundSectionContainer>
);
